<template>
  <div
    :class="[
      snoozed
        ? [
            'Requested Blossom',
            'Requested Estriol Cream with Tretinoin',
          ].includes(patientInfo.status)
          ? 'tw-bg-[#a783ff]'
          : patientInfo.status === 'Transferred Patient'
          ? 'tw-bg-[#ff8d40]'
          : 'tw-bg-muted'
        : patientInfo.action === 'Verify ID'
        ? 'tw-bg-muted'
        : bgColor,
      isClicked && !viewOnly ? 'card-dashboard-item-focus' : '',
      viewOnly ? 'card-dashboard-darkmode' : 'card-dashboard-item',
    ]"
    class="tw-relative tw-rounded-md tw-pt-2 tw-mb-4 tw-shadow-none tw-transition-all hover:tw-shadow-lg"
    @click="isClicked = !isClicked"
    v-click-outside="onClickOutside"
  >
    <!-- snoozed -->
    <span
      v-if="snoozed"
      class="tw-block tw-w-full tw-text-center tw-px-4 tw-pb-1 tw--mt-1 tw-text-[10px] tw-text-white"
      :class="
        [
          'Requested Blossom',
          'Requested Estriol Cream with Tretinoin',
        ].includes(patientInfo.status)
          ? 'tw-bg-[#a783ff]'
          : patientInfo.status === 'Transferred Patient'
          ? 'tw-bg-[#ff8d40]'
          : 'tw-bg-muted'
      "
    >
      {{ snoozedText }}
    </span>
    <div
      v-if="patientInfo.status === 'New Admin Message'"
      class="tw-text-center tw-bg-white tw-pt-1 from-admin"
    >
      From Admin
    </div>
    <div
      class="card-dashboard-content tw-grid tw-grid-cols-2 tw-px-2 tw-pt-3"
      :class="viewOnly ? 'tw-rounded-b-md' : 'tw-rounded-b-sm'"
    >
      <span>{{ name }}</span>
      <span class="tw-text-right">{{ timeStamp }}</span>
      <span class="card-location tw-mt-1 tw-text-[#4F4F4F] tw-text-[12px]">
        {{ date }} | {{ location }}
      </span>
      <div class="tw-col-span-2 tw-mt-3">
        <slot name="message"></slot>
      </div>

      <!-- disabled -->
      <div
        class="tw-col-span-2 tw-text-[10px] tw-pb-1 tw-opacity-50 tw-text-center"
        v-if="disabled"
      >
        {{ disabledText }}
      </div>
    </div>
    <div
      v-if="!viewOnly"
      class="btn-holder tw-flex tw-h-[40px] tw-w-full tw-rounded-b-md tw-transition-all"
    >
      <button
        v-if="patientInfo.status === 'Follow-Up'"
        class="snooze tw-border-0 tw-text-center tw-w-full tw-h-[40px]"
        @click="$router.push(`/patients/${patientInfo.id}/intake`)"
      >
        View Intake History
      </button>
      <button
        v-if="clearBtn"
        class="clear tw-border-0 tw-text-center tw-w-full tw-h-[40px]"
        @click="onClearMessage"
      >
        Clear
      </button>
      <button
        v-if="ignoreBtn"
        class="clear tw-border-0 tw-text-center tw-w-full tw-h-[40px]"
        @click="onIgnore"
      >
        Ignore
      </button>
      <button
        v-if="snoozeBtn && !snoozed"
        class="snooze tw-border-0 tw-text-center tw-w-full tw-h-[40px]"
        @click="onSnoozeMessage"
      >
        Snooze
      </button>
      <button
        :class="patientInfo.status === 'New Admin Message' ? 'reply' : bgColor"
        class="tw-border-0 tw-text-center tw-w-full tw-h-[40px]"
        :disabled="disabled"
        @click="onActionClick"
      >
        {{ buttonText }}
      </button>
      <button
        v-if="['Pending Message', 'Transferred'].includes(patientInfo.action)"
        class="view-profile tw-border-0 tw-text-center tw-w-full tw-h-[40px]"
        @click="$router.push(`/patients/profile/${patientInfo.id}`)"
      >
        View Profile
      </button>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  name: "CardDashboardItem",
  props: {
    bgColor: {
      type: String,
    },
    name: {
      type: String,
    },
    timeStamp: {
      type: String,
    },
    location: {
      type: String,
    },
    date: {
      type: String,
    },
    disabledText: {
      type: String,
    },
    snoozedText: {
      type: String,
    },
    snoozed: {
      type: Boolean,
    },
    clearBtn: {
      type: Boolean,
    },
    ignoreBtn: {
      type: Boolean,
    },
    snoozeBtn: {
      type: Boolean,
    },
    hideButton: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    buttonText: {
      type: String,
      default: "Accept",
    },
    patientInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isClicked: false,
    };
  },
  computed: {
    viewOnly() {
      return Boolean(this.$store.state.globals.currentDoctor.id);
    },
  },
  methods: {
    async onActionClick() {
      if (this.buttonText === "Accept") {
        try {
          await this.$store.dispatch(
            "patients/acceptPatient",
            this.patientInfo.id
          );
          this.$router.push(`/verification/${this.patientInfo.id}`);
        } catch (e) {
          console.error(e);
          this.$emit("intake-error", e.message);
          this.$bvModal.show("dashboard-error");
        }
      } else if (this.buttonText === "Message") {
        this.$router.push(`/patients/${this.patientInfo.id}/message`);
      } else if (this.buttonText === "Verify") {
        this.$router.push(`/verification/${this.patientInfo.id}`);
      } else if (this.buttonText === "Reply") {
        if (this.patientInfo.status === "New Admin Message") {
          this.$router.push(
            `/patients/${this.patientInfo.patientid}/message?type=admin`
          );
        } else {
          if (this.patientInfo.admin) {
            this.$router.push(
              `/patients/${this.patientInfo.patientid}/message?type=admin`
            );
          } else {
            this.$router.push(`/patients/${this.patientInfo.id}/message`);
          }
        }
      } else if (
        this.buttonText === "View Profile" ||
        this.buttonText === "View Intake History"
      ) {
        this.$router.push(`/patients/profile/${this.patientInfo.id}`);
      } else if (this.buttonText === "Mark as Complete") {
        this.$bvModal.show("markcomplete");
        this.$emit("update:selected-patient", {
          patientname: this.name,
          patientid: this.patientInfo.id,
          patientfollowupid: this.patientInfo.patientfollowupid,
          followuptype: this.patientInfo.followupType,
        });
      } else if (this.buttonText === "Prescribe") {
        this.$router.push(`/prescriptions/order/${this.patientInfo.id}`);
      }
    },
    onClearMessage() {
      if (
        this.patientInfo.status === "New Message" ||
        this.patientInfo.status === "New Admin Message"
      ) {
        this.$emit("clear-message", {
          conversationid: this.patientInfo.conversationid,
          latestmessages: this.patientInfo.latestmessages.map((message) => ({
            ...message,
            fullname: this.patientInfo.name,
          })),
          patientid: this.patientInfo.id,
        });
      } else {
        this.$emit("ignore-patient", { patientInfo: this.patientInfo });
      }
    },
    onSnoozeMessage() {
      this.$emit("snooze-message", {
        conversationid: this.patientInfo.conversationid,
      });
    },
    onIgnore() {
      this.$emit("ignore-patient", { patientInfo: this.patientInfo });
    },
    onClickOutside() {
      this.isClicked = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.unread,
.reply,
.view-profile {
  background-color: #1a6a72;

  &:is(button) {
    background-color: #1a6a72;
    color: #edffe1;
  }
}

.prescribe {
  background-color: #a783ff;

  &:is(button) {
    background-color: #a783ff;
    color: #edffe1;
  }
}

.ignored {
  background-color: #828282;

  &:is(button) {
    background-color: #828282;
    color: #fff;
  }
}

.message,
.admin-message {
  background-color: #ff8d40;

  &:is(button) {
    background-color: #ff8d40;
    color: #fff;
  }
}

.migrate {
  background-color: #fe8d3f;

  &:is(button) {
    background-color: #fe8d3f;
    color: #fff;
  }
}

.clear {
  background-color: #e63757;
  color: #ffffff;
}

.snooze {
  background-color: #828282;
  color: #ffffff;
}

.card-dashboard-content {
  background-color: $white;
}

.card-dashboard-item {
  padding-bottom: 0;

  .btn-holder {
    position: absolute;
    bottom: -40px;
    opacity: 0;
  }
}

.card-dashboard-item:hover,
.card-dashboard-item-focus {
  padding-bottom: 40px;

  // &.ignored:hover {
  //   padding-bottom: 0;
  // }

  .btn-holder {
    bottom: 0;
    opacity: 1;
  }
}

.btn-holder {
  overflow: hidden;
  button:disabled {
    opacity: 0.5;
  }
}

.darkmode .from-admin {
  background-color: #191c1c;
  color: white;
}
</style>
